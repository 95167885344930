import { graphql, useStaticQuery } from "gatsby";

const NotFoundQuery = () => {
  const { sanityNotFound } = useStaticQuery(graphql`
    query SiteNotFoundQuery {
      sanityNotFound(_id: { eq: "notFound" }) {
        copy
        title
      }
    }
  `);
  return sanityNotFound;
};
export default NotFoundQuery;
