import { Link } from "gatsby";
import React from "react";
import NotFoundQuery from "../static-queries/notFoundQuery";

const NotFoundPage = () => {
  const notFoundPage = NotFoundQuery();

  return (
    <section className="h-screen w-full relative text-center container mt-mnav md:mt-dnav flex flex-col place-content-center md:border-t border-black">
      <h1 className="text-xl md:text-3xl font-semibold mb-5 font-sans">
        {notFoundPage.title}
      </h1>
      <p className="font-serif mb-20 text-base">{notFoundPage.copy}</p>
      <div className="md:mb-20">
        <Link className="button--outline" to="/">
          Back to Home
        </Link>
      </div>
    </section>
  );
};

export default NotFoundPage;
